<template>
  <div class="input-image" @click="$refs.img_input.click()">
    <input
      ref="img_input"
      type="file"
      style="display: none"
      accept="image/*"
      @change="handleFiles"
    >
    <template v-if="this.$slots.default">
      <slot/>
    </template>
    <template v-else>
      <fieldset class="input-image rounded d-flex flex-column align-stretch">
        <Loading :value="loading" />
        <legend class="v-label v-label--active">{{ $t('INPUT.IMAGE') }}</legend>
        <div class="input-image-remove" v-if="isImages">
          <v-btn
            fab
            x-small
            depressed
            color="white"
            @click.stop="removeImages"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <div :key="keyImg">
          <div v-if="onlyTitle" class="text-ellipsis text-nowrap ma-2">
            <small>{{ (Object.keys(images || {}).length > 0 ? '1 image' : 'Click to download') }}</small>
          </div>
          <div v-else class="pr-2 pl-2 input-image-upload-area" >
            <img
              :key="keyImg"
              :src="$getImgSrc(images, 0, '/img/empty-image.png')"
              width="100%"
              class="rounded"
            >
          </div>
        </div>
      </fieldset>
    </template>
  </div>

  <!--
  <v-row class="ma-0 height-100">
    <v-col cols="3" class="pa-0 height-100 d-flex">
      <div class="pa-2 input-image-thumbnail d-flex align-center">
      </div>
      <v-divider vertical />
    </v-col>
    <v-col cols="9" class="align-stretch pa-0">
      <input
      ref="img_input"
      type="file"
      style="display: none"
      accept="image/*"
      @change="handleFiles"
      >
      <div class="d-flex pr-2 pl-4 pa-2">
        <div class="grey--text pt-1">
          <strong>{{$t('UPLOAD.UPLOAD_FILE_LIST')}}</strong>
          <small class="ml-2">{{$tc('UPLOAD.UPLOAD_FORMAT', innerFormats.length)}}</small>
        </div>
        <v-spacer />
        <v-btn rounded small class="custom" color="grey lighten-2" @click="$refs.img_input.click()">
          <v-icon class="mr-2" size="20">mdi-upload</v-icon>{{$t('UPLOAD.UPLOAD_FILE')}}
        </v-btn>
      </div>
      <v-divider />
      <div class="files-list mt-2" :key="keyImg">
        <div v-for="(format,i) in innerFormats" :key="'form'+i" class="pl-4">
          <v-icon size="18" class="mr-2">mdi-file-image-outline</v-icon>
          <small>
            <strong class="text-capitalize">{{format.name}}:</strong>
            <span> {{ images[format.name] ? images[format.name] : $t('FILE_UPLOAD_NO_IMAGE') }}</span>
          </small>
        </div>
      </div>
    </v-col>
    <v-col cols="12" v-if="debug" class="pa-0 grey lighten-3">
      <v-divider />
      <div ref="canvases" class="input-image-canvases pa-4"></div>
    </v-col>
  </v-row>
  -->

</template>

<style lang="scss">

fieldset.input-image:hover {
  border: solid 1px rgba(23, 22, 37, 0.675);
  color: solid 1px rgba(23, 22, 37, 0.675);
}

fieldset.input-image {
  height: 100%;
  min-height: 36px;
  position: relative;
  margin-top: -22px;
  border: solid 1px rgba(0, 0, 0, 0.38);
  color: solid 1px rgba(0, 0, 0, 0.38);
  transition: all 0.3s;
}

.select-image-input {
  fieldset.input-image {
    min-height: 89px;
  }
}


fieldset.input-image legend {
  transform: scale(0.75);
  margin-left: 5px;
  font-size: 16px;
  padding: 2px;
}

.input-image-canvases canvas {
  max-width: 100%;
}

.input-image-upload-area {
  cursor: pointer;
}

.select-image-input {
  .input-image-upload-area {
    display: flex;
    height: 68px;
    align-items: center;
  }
}

.input-image-remove {
  position: absolute;
  top:-8px;
  right: 0;
}

</style>

<script>
const _createFileReader = (file) => new Promise((resolve, reject) => {
  const fr = new FileReader()
  fr.readAsDataURL(file)
  const onerror = () => {
    fr.removeEventListener('load', onload)
    fr.removeEventListener('error', onerror)
    reject(new Error(`File can not be loaded: ${  file.name  }.`))
  }
  const onload = () => {
    fr.removeEventListener('load', onload)
    fr.removeEventListener('error', onerror)
    resolve({name: file.name, result: fr.result})
  }
  fr.addEventListener('load', onload)
  fr.addEventListener('error', onerror)
})

const _fileLimit = 20 * 1024 * 1024 // 5mo
const _fileRegex = /image\/(jpg|jpeg|png|svg|bmp|vnd.microsoft.icon)/
const _readFiles = (files, multi = false) => {
  const promises = []
  for (let i = 0, len = multi ? files.length : 1; i < len; i++) {
    const file = files[i]
    if (typeof file !== 'undefined') {
      if (file.size < _fileLimit) {
        if (file.type.match(_fileRegex)) {
          promises.push(_createFileReader(file))
        } else return Promise.reject(new Error(`Invalid file type: ${  file.name  }.`))
      } else return Promise.reject(new Error(`File too big: ${  file.name  }.`))
    } else return Promise.reject(new Error('Invalid file as undefined.'))
  }
  return Promise.all(promises)
}

const _createImage = (src) => new Promise((resolve, reject) => {
  const image = new Image()
  image.onload = () => resolve(image)
  image.onerror = (e) => reject(e)
  image.src = src
})

const _getImgSize = (image) => {
  const size = {
    width: image.naturalWidth || image.clientWidth || image.width,
    height: image.naturalHeight || image.clientHeight || image.height,
  }
  size.ratio = size.width / size.height
  return size
}

const _createCanvases = (image, formats = []) => {
  const size = _getImgSize(image)
  const canvases = []
  for (let i = 0, len = formats.length; i < len; i++) {
    const format = formats[i]
    const canvas = document.createElement('canvas')
    canvas.width  = format.width  === 'auto' ? size.width  : (format.width  || (format.height * size.ratio))
    canvas.height = format.height === 'auto' ? size.height : (format.height || (format.width  / size.ratio))
    canvas.format = format
    canvas.format.encoding = format.encoding || 'jpeg'
    if (canvas.format.encoding === 'jpg') canvas.format.encoding = 'jpeg'
    canvas.format.quality = format.quality || 0.6
    const context = canvas.getContext('2d')
    context.clearRect(0, 0, canvas.width, canvas.height)
    context.globalCompositeOperation = 'source-out'
    const resize = {dx:0, dy:0}
    if (format.crop) {
      if (canvas.height <= canvas.width) {
        resize.height = size.height * (canvas.height / size.height)
        resize.width = resize.height * size.ratio
        resize.dx = (canvas.width - resize.width) / 2
      } else {
        resize.width = size.width * (canvas.width / size.width)
        resize.height = resize.width / size.ratio
        resize.dy = (canvas.height - resize.height) / 2
      }
    } else {
      if (canvas.height <= canvas.width) {
        resize.width = size.width * (canvas.width / size.width)
        resize.height = resize.width / size.ratio
        resize.dy = (canvas.height - resize.height) / 2
      } else {
        resize.height = size.height * (canvas.height / size.height)
        resize.width = resize.height * size.ratio
        resize.dx = (canvas.width - resize.width) / 2
      }
    }

    context.drawImage(
      image,
      resize.dx,
      resize.dy,
      resize.width,
      resize.height
    )
    canvases.push(canvas)
  }
  return canvases
}

import Loading from '@/components/Loading'

const _uploadSimulation = () => new Promise((resolve) => {
  setTimeout(() => {
    resolve()
  },2000)
})

export default {
  name: 'InputImage',
  components: {
    Loading
  },
  data: () => ({
    name: undefined,
    originalImage: undefined,
    originalSize: undefined,
    images: undefined,
    innerFormats: [],
    keyImg: 0,
    loading: false,
  }),
  props: {
    value: {
      type: [String, Object],
      required: false
    },
    formats: {
      type: [Array, Object],
      required: false,
    },
    keepOriginal: {
      type: Boolean,
      required: false,
      default: false
    },
    keepOriginalName: {
      type: Boolean,
      required: false,
      default: false
    },
    randomName: {
      type: Boolean,
      required: false,
      default: false
    },
    timestampName: {
      type: Boolean,
      required: false,
      default: false
    },
    debug: {
      type: Boolean,
      required: false,
      default: false
    },
    onlyTitle: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  watch: {
    value (n) {
      this.images = Object.assign(this.images || {}, n || {})
      this.keyImg++
    },
  },

  computed: {
    isImages() {
      return !(!this.value || Object.keys(this.value).length == 0)
    }
  },

  mounted () {
    this.innerFormats = [...(this.formats || [])]
    if (this.innerFormats.length === 0 || this.keepOriginal) {
      this.innerFormats.unshift({
        name: 'original',
        width: 'auto',
        height: 'auto',
      })
    }
    this.images = this.$clone(this.value || {})
    this.keyImg++
  },

  methods: {

    removeImages () {
      this.images = null
      this.$refs.img_input.value = ''
      this.keyImg++
      this.$emit('input', this.images)
      this.$emit('change', this.images)
    },

    handleFiles (e) {
      const promises = []
      _readFiles(e.target.files).then(files => {
        _createImage(files[0].result).then(image => {
          const canvases = _createCanvases(image, this.innerFormats)
          if (this.debug) this.$refs.canvases.innerHTML = ''
          canvases.forEach((canvas, i) => {
            if (this.debug) {
              const p = document.createElement('p')
              p.innerHTML = `<small>format: ${  JSON.stringify(canvas.format)  }</small>`
              this.$refs.canvases.appendChild(p)
              this.$refs.canvases.appendChild(canvas)
            }
            // prepare image name
            if (!this.images) this.images = {}
            let imgName = canvas.format.name
            if (this.keepOriginalName) {
              imgName = name
            } else if (this.randomName) {
              imgName = (Math.round(Date.now() + Date.now() * Math.random())).toString(36).replace(/\W/gmi, '')
            }
            if (this.timestampName) {
              imgName += '-' + Date.now()
            }
            imgName = imgName.replace(/\s+/gmi,'_').toLowerCase() // avoid space and uppercase in name
            //
            this.images[imgName] = canvas.toDataURL(`image/${canvas.format.encoding}`, canvas.format.quality)
            this.keyImg++
            this.$emit('input', this.images)
            this.$emit('change', this.images)
          })
        })
      })
    },
  }
}
</script>
