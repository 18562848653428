<template>
  <div class="form-stepper form-stepper-job">
    <v-form
      ref="form"
      v-model="valid"
      class="form pa-6"
    >

      <v-subheader class="pb-4 pl-0">
        <v-icon class="mr-2" size="16">mdi-briefcase-check-outline</v-icon>
        {{ $t('JOB.SUBTITLE_JOB') }}
      </v-subheader>

      <v-row class="ma-0">
        <v-col cols="8" class="pa-0">
          <v-text-field
            dense
            v-model="title"
            :rules="[$rules.required, $rules.maxlength(255)]"
            :label="$t('INPUT.TITLE')"
            :placeholder="$t('INPUT.TITLE')"
            outlined
            required
          />
        </v-col>
        <v-col cols="4" class="pl-4 pa-0">
          <v-text-field
            dense
            v-model="ref"
            :rules="[$rules.required, $rules.maxlength(255)]"
            :label="$t('INPUT.REF')"
            :placeholder="$t('INPUT.REF')"
            outlined
            required
          />
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="6" class="pa-0">
          <v-text-field
            dense
            v-model="url"
            :rules="[$rules.required, $rules.maxlength(255)]"
            :label="$t('INPUT.URL')"
            :placeholder="$t('INPUT.URL')"
            outlined
            required
          />
        </v-col>
        <v-col cols="6" class="pa-0 pl-1">
          <v-text-field
            prepend-inner-icon="mdi-calendar"
            dense
            v-model="start_date"
            :rules="[$rules.required, $rules.date]"
            :label="$t('INPUT.DATE')"
            :placeholder="$t('INPUT.DATE')"
            outlined
            required
          />
        </v-col>
      </v-row>
      <v-row class="ma-0 pb-7">
        <v-col cols="12" class="pa-0">
          <v-autocomplete
            class="autocomplete-wrap"
            prepend-inner-icon="mdi-map-marker"
            dense
            no-filter
            v-model="location"
            :items="locationItems"
            :search-input.sync="locationInput"
            :label="$t('JOB.FIELD_LOCATION')"
            :placeholder="$t('JOB.FIELD_LOCATION_PLACEHOLDER')"
            outlined
            return-object
          >
            <template v-slot:selection="data">
              <span
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
              >
              {{ data.item.location }}
              </span>
            </template>
            <template v-slot:item="data">
              {{ data.item.location }}
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-textarea
            dense
            height="250px"
            v-model="description"
            :rules="[$rules.required, $rules.maxlength(10000)]"
            :label="$t('INPUT.DESCRIPTION')"
            :placeholder="$t('INPUT.DESCRIPTION')"
            outlined
            required
          />
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="9" class="pa-0">
          <v-textarea
            dense
            height="155px"
            v-model="profile"
            :rules="[$rules.maxlength(3000)]"
            :label="$t('INPUT.PROFILE')"
            :placeholder="$t('INPUT.PROFILE')"
            outlined
          />
        </v-col>
        <v-col cols="3" class="pa-0 pl-2">
          <input-image
            v-model="images"
            :formats="[
             {name: 'lg', height: 720 },
             {name: 'md', height: 360 },
             {name: 'sm', height: 180 }
            ]"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-divider />

    <v-toolbar
      color="white"
      flat
      height="70px"
      class="form-footer"
    >
      <v-spacer />
      <v-btn
        color="primary custom"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <strong>{{ $t('COMMON.CONTINUE') }}</strong>
        <v-icon class="ml-4">mdi-arrow-right</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<style>

.autocomplete-wrap .v-select__selections {
  flex-wrap: nowrap;
}
.autocomplete-wrap .v-select__selections .v-chip--select {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 50%;
}

</style>

<script>
import InputImage from '@/components/InputImage'

export default {
  name: 'FormJobStepIntro',
  components: {
    InputImage
  },
  data: (_this) => ({
    loading: false,
    valid: false,
    locationInput: '',
    locationItems: [],
    locationSearchTimeout: 0,
    locationLoading: false,
    //
    title: null,
    ref: null,
    url: null,
    location: null,
    images: null,
    description: null,
    start_date: new Date().toISOString().substr(0,10),
    profile: null,
    esco4_code: null,
    rome_code: null,
  }),
  props: {
    values: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    values (n) {
      this.init()
    },
    locationInput (n) {
      this.searchLocationItems(n)
    }
  },
  mounted () {
    this.searchLocationItems()
    this.init()
  },
  methods: {

    init () {
      Object.keys(this.values).forEach((key, i) => {
        if (key === 'location') {
          this.location = {
            location: this.values.location,
            location_city: this.values.location_city,
            location_city_code: this.values.location_city_code,
            location_department: this.values.location_department,
            location_department_code: this.values.location_department_code,
            location_region: this.values.location_region,
            location_country: this.values.location_country,
            location_country_code: this.values.location_country_code,
          }
          this.locationItems = [this.location]
        } else if (typeof this.values[key] !== 'undefined' && typeof this[key] !== 'undefined') {
          this[key] = this.values[key]
        }
      })
      if (!this.location || !this.location.location) {
          this.locationInput = this.location
      }
    },

    searchLocationItems (n) {
      const then = (response) => {
        let data = []
        if (response && response.data) data = response.data
        if (this.location) data = data.concat([this.location])
        this.locationItems = data
      }

      if (this.locationSearchTimeout) clearTimeout(this.locationSearchTimeout)
      this.locationLoading = true
      this.locationSearchTimeout = setTimeout(() => {
        if (n) {
          this.$services.api_programmatic
            .location.search({where:{title:n}, options: {limit: 10}})
            .then(then)
        }else {
          Promise.resolve().then(then)
        }
      }, 200)
    },

    async loadOfficeItems (options = {}) {
      try {
        this.loading = true
        const defaultOptions = {
          order: [['updated_at', 'DESC']],
          limit: 1000,
        }
        const query = this.$queryFormat({
          options: Object.assign({}, defaultOptions, options),
          where: { status: [1] }
        })
        const response = await this.$services.api_user.office.search(query)
        if (response.data) {
          this.officeItems = response.data
        } else {
          this.officeItems = []
        }
      }
      finally {
        this.loading = false
      }
    },

    validate () {
      if (this.$refs.form.validate()) {
        const data = {}
        if (this.ref) data.ref = this.ref
        if (this.title) data.title = this.title
        if (this.url) data.url = this.url
        if (this.start_date) data.start_date = this.start_date
        if (this.description) data.description = this.description
        if (this.profile) data.profile = this.profile
        if (this.location) {
          data.location = this.location.location
          data.location_city = this.location.location_city
          data.location_city_code = this.location.location_city_code
          data.location_department = this.location.location_department
          data.location_department_code = this.location.location_department_code
          data.location_region = this.location.location_region
          data.location_country = this.location.location_country
          data.location_country_code = this.location.location_country_code
        }
        this.$emit('childData', data)
        this.$emit('validation', true)
        this.$emit('next')
      } else {
        this.$emit('validation', false)
      }
    },
  },
}
</script>
